import gettokenBg from 'img/home-gettoken-bg.jpg';
import style from './styles.module.sass';
import Button from 'components/button';
import Icon from 'components/icon';
import Input from 'components/input';
import { useLayoutEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Web3 from 'web3';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export default function Buy({user}) {

  const { t } = useTranslation();
  const [amount, setAmount] = useState('');
  const [wallet, setWallet] = useState({ check: false, addr: '' });
  const [err, setErr] = useState(false);
  const [payment, setPayment] = useState({ id: null, wait: false });
  const [terms, setTerms] = useState(true);

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div className={style.buy} style={{ backgroundImage: `url(${gettokenBg})` }}>
      <div>
        <div className='head1 center'>{t('buy.buy-tokens-from-eve')}</div><br></br>
        <div className={style.login}>
          <div>
            <b>{t('header.login')}</b>
          </div>
          <div>
            <div className='grey'>{user.email}</div>
            <div>
              <Icon i='ok-circled violet' /><span className='grey'>{t('buy.email-verified')}</span>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className={style.tokens}>
          <div>
            <b>{t('account.tokens')}</b>
          </div>
          <div>
            <div className='grey'>{t('buy.number-of-tokens')}</div>
            <div>
              <Input
                placeholder='amount'
                type='numbers'
                value={amount || ''}
                onChange={v => setAmount(v)} />
              <span>&nbsp; €{amount}</span>
            </div>
          </div>
        </div>
        <hr />
        <div className={style.wallet}>
          <div>
            <b>{t('buy.wallet')}<Icon i='ok-info' /></b>
          </div>
          <div>
            <div>
              <input
                type="checkbox"
                onChange={() => setWallet({ ...wallet, check: !wallet.check })} />
              &nbsp;{t('buy.i-want-to-use-my-own')}</div>
            <div><Icon i='down' /></div>
          </div>
          <div>
            <div>{t('withdrawal.wallet-address')}</div>
            <div>
              <Input
                disabled={!wallet.check}
                onChange={(v) => setWallet({ ...wallet, addr: v })} />
            </div>
          </div>
        </div>
        <hr></hr>

        <div className={style.terms}>
          <input
            type="checkbox"
            onChange={()=>setTerms(!terms)}
            defaultChecked/>
          <span className={terms ? '' : 'red'}>&nbsp;{t('buy.i-have-read-the-term')}</span>
        </div>
        <br></br>
        {err && <div className={style.err}><Icon i='attention' /> {err}</div>}
        <Button
          onClick={() => pay()}
          disabled={!terms}
        >
          {payment.wait ? <Icon i='spin' /> : t('buy.buy-token')}
        </Button>
      </div>
    </div>
  );

  function pay() {
    if (amount < 10) { setErr(t('error.wrong-token-amount-m')); return; }
    if (wallet.check && !Web3.utils.isAddress(wallet.addr)) { setErr(t('withdrawal.wrong-wallet-address')); return; }
    setPayment({ ...payment, id: uuidv4() });
    setErr(false);

    axios.post('/payment', { id: uuidv4(), amount: parseFloat(amount), ...(wallet.check && {address: wallet.addr }) }, {
      headers: {
        'x-auth-token': localStorage.getItem('AuthToken'),
        'content-type': 'text/json'
      }
    })
      .then((res) => {
        window.location.replace(res.data.redirectUrl);
      })
      .catch(error => {
        console.warn(error.message);
        alert(t('error.something-went-wrong'));
      });
  }
}

