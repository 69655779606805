import gettokenBg from 'img/home-gettoken-bg.jpg';
import gettokenGirl from  'img/home-gettoken-girl.png';
import phonesImg from  'img/home-phones.png';
import wehelpImg from  'img/home-wehelp.png';
import style from './styles.module.sass';
import Button from 'components/button';
import Icon from 'components/icon';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Home() {
  const { t } = useTranslation();

  return (
    <div>
      <section className={style.gettoken} style={{ backgroundImage: `url(${gettokenBg})` }}>
        <div>
          <div>
            <div>{t('home.get-your-token-here')}</div>
            <div>{t('home.have-the-opportunity')}</div>
            <Link to='/buy'><Button>{t('home.get-your-token')}</Button></Link>
          </div>
          <div>
            <img src={gettokenGirl} decoding="async" alt="" loading="lazy"/>
          </div>
        </div>
      </section>
      <section className={style.wehelp}>
        <div>
          <div>
            <img src={wehelpImg} decoding="async" alt="" loading="lazy"/>
          </div>
          <div>
            <div>{t('home.we-help-you')}</div>
            <div>{t('home.our-software-eve')}
            </div>
          </div>
        </div>
      </section>
      <section className={style.offers}>
        <div>
        <div>{t('home.eve-hr-offers')}</div>
          <Link to='/buy' preventScrollReset={false}><Button>{t('home.get-your-token')}</Button></Link>
        </div>
      </section>
      <section className={style.smart}>
        <div>
          <div>
            <div className={style.smartblock}>
              <Icon i='algo'/>
              <div>
                <div>{t('home.integrated')}</div>
                <div>{t('home.eve-integrates')}</div>
              </div>
            </div>
            <div className={style.smartblock}>
            <Icon i='chat'/>
              <div>
                <div>{t('home.smart-messages')}</div>
                <div>{t('home.eve-learns-from-your')}</div>
              </div>
            </div>
            <div className={style.smartblock}>
              <Icon i='analytic'/>
              <div>
                <div>{t('home.smart-data')}</div>
                <div>{t('home.eve-uses-your-employees-digital')}</div>
              </div>
            </div>
          </div>
          <div>
            <img src={phonesImg} decoding="async" alt="" loading="lazy"/>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
