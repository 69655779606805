import ReactDOM from 'react-dom/client';
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider, Navigate } from "react-router-dom";
import 'App.sass';
import Header from 'components/header';
import Home from 'pages/home';
import Account from 'pages/account';
import Buy from 'pages/buy';
import Activate from 'pages/activate';
import LoginPage from 'pages/login/loginpage.jsx';
import ResumePayment from 'pages/buy/resumepayment';
import Page404 from 'pages/404';
import Reset from 'pages/reset';
import reportWebVitals from 'reportWebVitals';
import "fontello/css/fontello.css";
import Footer from 'components/footer';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Auth from 'controller/auth';
import 'i18n/config';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

function App() {
  const [auth, setAuth] = useState(false);

  useEffect(()=> {
    fetchData();
    async function fetchData() {
      const res = await Auth();
      setAuth(res);
    }}, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route element={<><Header user={auth}/><Outlet /><Footer /></>} >
          <Route path="/" element={<Home />} />
          <Route path="/loginpage" element={<LoginPage />} />
          <Route path="activate/:id" element={<Activate />} />
          <Route path="reset/:secret" element={<Reset />} />
          <Route path="account" element={auth ? <Account user={auth}/> : <LoginPage />} />
          <Route path="buy" element={auth ? <Buy user={auth}/> : <Navigate to="/loginpage"/>} />
          <Route path="resumePayment/:id" element={<ResumePayment />} />
          <Route path="*" element={<Page404 />} />
        </Route>
      </Route>
    )
  );

  return <RouterProvider router={router} />
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
