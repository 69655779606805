import logoImg from 'img/logo-blackpink.png';
import style from './styles.module.sass';
import Button from 'components/button';
import Input from "components/input";
import { useState } from 'react';
import Icon from 'components/icon';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function Login(props) {

  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [wait, setWait] = useState(false);
  const [err, setErr] = useState(false);

  return (<div>
    <div className={style.signup}>
      <img src={logoImg} alt=''></img>
      <div>
        <div>
          <span>{t('header.login')}</span><span></span><span onClick={() => props.onSignup()}>{t('login.sign-up')}</span>
        </div>
        <Input type='email' placeholder={t('login.email')} onChange={v => setEmail(v)} />
        <Input type='password' placeholder={t('login.password')} onChange={v => setPassword(v)} />
        <div className={style.forgot} onClick={()=>window.location.replace('/reset/new')}>
          {t('login.forgot-password')}
        </div>
        {err && <div className={style.err}><Icon i='attention' /> {t('login.email-or-password-wr')}</div>}
        <Button onClick={() => login()}>
          {wait ? <Icon i='spin' /> : t('login.log-in')}
        </Button>
      </div>
    </div>
  </div>);

  function login() {

    setWait(true);
    setErr(false);
    axios.post('/user/login', { email, password })
      .then((res) => {
        setWait(false);
        localStorage.removeItem('AuthToken');
        localStorage.setItem('AuthToken', res.data.token);
        setTimeout(() => window.location.replace('/account'), 500);
      })
      .catch(error => {
        console.warn(error.message); setWait(false); setErr(true);
      });
  }
}

export default Login;
