import style from './styles.module.sass';
import logoImg from 'img/logo-blackpink.png';
import { useTranslation } from 'react-i18next';

export default function Footer() {
	const { t } = useTranslation();

	return <div className={style.wrapper}>
		<div className={style.footer}>
			<div>
				<img src={logoImg} alt=''/>
			</div>
			<div>
				<div>{t('footer.eve-hr-software-ag')}</div>
				<div>{t('footer.che-236-437-772')}</div>
				<div>
					<div>{t('footer.mattli-14-6365')}</div>
					<div>{t('footer.kehrsiten-switzerlan')}</div>
				</div>
			</div>
			<div>
				<div>{t('footer.contact')}</div>
				<a href={`mailto:${t('footer.info-evetoken-io')}`}>{t('footer.info-evetoken-io')}</a>
				<div>{t('footer.41-0-765045738')}</div>
			</div>
			<div>
				<div>{t('footer.privacy')}</div>
				<a href='https://www.evehr.io/terms-privacy/'>{t('footer.terms-and-privacy')}</a>
				<a href='https://www.evehr.io/terms-privacy/'>{t('footer.privacy-policy')}</a>
			</div>
		</div>
		<hr></hr>
		<div className={style.copyright}>{t('footer.2023-c-copyright-eve')}</div>
	</div>;
}
