import style from './styles.module.sass';
import Button from 'components/button';
import { useParams } from 'react-router-dom';
import Input from 'components/input';
import Icon from 'components/icon';
import { useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

export default function Reset() {

  const { t } = useTranslation();
  let { secret } = useParams();
  const [passw, setPassw] = useState(false);
  const [email, setEmail] = useState(false);
  const [wait, setWait] = useState(false);

  return (<div className={style.page}>
    { secret==='new'
      ? <>
          <div>{t('reset.email')}</div>
          <Input type='email' onChange={v=>setEmail(v)}/>
          <Button onClick={password} disabled={!email}>
          { wait ? <Icon i='spin'/> : t('reset.reset-password')}
          </Button>
        </>
      : <>
          <div>{t('reset.new-password')}</div>
          <Input onChange={v=>setPassw(v)}/>
          <Button onClick={confirm} disabled={!passw}>
            { wait ? <Icon i='spin'/> : t('reset.set-new-password')}
          </Button>
      </>
    }
  </div>);

  function password() {

    setWait(true);
    axios.post('user/password', { email, id: uuidv4() }, {
      headers: {
        'content-type': 'text/json'
      }
    })
      .then(() => {
        setEmail('');
        alert(t('reset.password-reset-email'));
      })
      .catch(error => {
        console.warn(error);
        alert(t('error.something-went-wrong'));
      })
      .finally(()=>setWait(false))
  }

  function confirm() {

    setWait(true);
    axios.post('/user/password/confirm', { secret, password: passw }, {
      headers: {
        'content-type': 'text/json'
      }
    })
      .then(() => window.location.replace('/loginpage'))
      .catch(error => {
        console.warn(error);
        alert(t('error.something-went-wrong'));
      })
      .finally(()=>setWait(false))
  }
}
