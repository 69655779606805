import axios from "axios";
import Button from "components/button";
import Icon from "components/icon";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import style from './styles.module.sass';
import { useTranslation } from 'react-i18next';

function ResumePayment() {
	const { t } = useTranslation();
	const { id } = useParams();
	// eslint-disable-next-line
	useEffect(() => finalise(), []);

	const [res, setRes] = useState('waiting');

	return <div className={style.resume}>
			{res==='waitng' && <>
				<div>{t('resume-payment.payment-processing')}</div>
				<div><Icon i='spin'/></div>
			</>}
			{res==='success' && <>
				<p>{t('resume-payment.congratulation-payme')}</p>
				<p>{t('resume-payment.eve-tokens-added-to-')}</p>
				<Link to='/account'>
					<Button>{t('resume-payment.back-to-profile')}</Button>
				</Link>
			</>}
			{res==='error' && <>
				<Button onClick={()=>window.location.reload()}>{t('error.something-went-wrong')}</Button>
			</>}
		</div>;

	function finalise() {

		axios.post('/payment/finalise', { id }, {
			headers: {
				'x-auth-token': localStorage.getItem('AuthToken'),
				'content-type': 'text/json'
			}
		})
			.then(() => setRes('success'))
			.catch(error => {
				console.warn(error);
				if (error.response.data.error.indexOf('CANCELLED')!==-1) window.location.replace('/account');
				setRes('error');
			})
	}
}

export default ResumePayment;