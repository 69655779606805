import style from './styles.module.sass';
import boyImg from 'img/account-boy.png';
import gettokenBg from 'img/home-gettoken-bg.jpg';
import Button from 'components/button';
import Icon from 'components/icon';
import Input from 'components/input';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Popup from 'components/popup';
import Withdrawal from './withdrawal';
import { useTranslation } from 'react-i18next';

const TX_URL = process.env.REACT_APP_BSCSCAN + '/tx/';

function Account({ user }) {

  const { t } = useTranslation();
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [isChangeNameOpen, setIsChangeNameOpen] = useState(false);
  const [wallet, setWallet] = useState(null);
  const [isTransferOpen, setIsTransferOpen] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getWallet(), []);

  const Bill = ({ date, amount, txid }) => {
    return (<div className={style.bill}>
      <div>
        {new Date(date).toLocaleString().replaceAll('/', '-')}
        { txid &&
          <Link
          to={TX_URL + txid}
          target="_blank"
          rel="noopener noreferrer">
            <Icon i='export'/>
          </Link>
        }
      </div>
      <div>
        <div>
          <div>{t('account.tokens')}</div>
          <div>{amount}</div>
        </div>
        <div>
          <div>{t('account.paid')}</div>
          <div>€{amount}</div>
        </div>
      </div>
    </div>);
  }

  return (<>

    <div className={style.account} style={{ backgroundImage: `url(${gettokenBg})` }}>
      <div>
        <img src={boyImg} decoding="async" alt="" loading="lazy" />
      </div>
      <div>

        <div className={style.myaccount}>
          <div>{t('account.my-account')}</div>
          <div>
            {isChangeNameOpen
              ? <div className={style.change}>
                <Input type='text' value={firstName || ''} placeholder={t('account.first-name')} onChange={v => setFirstName(v)} />
                <Input type='text' value={lastName || ''} placeholder={t('account.last-name')} onChange={v => setLastName(v)} />
                <div>
                  <Button onClick={() => changeName()}>{isChangeNameOpen === 'wait' ? <Icon i='spin' /> : t('account.save')}</Button>
                  <Button onClick={() => setIsChangeNameOpen(false)}>{t('account.cancel')}</Button>
                </div>
                <Link to='/reset/new'>{t('account.change-password')}</Link>

              </div>
              : <div className={style.name}>
                <div>{firstName} {lastName}</div>
                <div>{user.email}</div>
                <div onClick={() => setIsChangeNameOpen(true)}>{t('account.change-details')}</div>
                { wallet && wallet.balance>0 &&
                  <Link to='/buy'><Button>{t('account.get-new-tokens')}</Button></Link>
                }
              </div>
            }
            <div className={style.total}>
              <div>{t('account.total-in-wallet')}</div>
              <div className={style.twallet}>
                <Icon i='wallet' />
                <div>
                  <div>{wallet && wallet.balance}</div>
                  <div>€{wallet && wallet.balance}</div>
                </div>
              </div>
              <Button onClick={() => setIsTransferOpen(true)}>{t('account.transfer-tokens')}</Button>
            </div>
          </div>
        </div>
        <div className={style.history}>
          <div>{t('account.wallet-history')}</div>
          <div>
            <div>
              <div className={style.smheading}>{t('account.eve-wallet-input')}</div>
              {wallet && wallet.history.filter(i => i.type === 'BUY').map(i =>
                <Bill amount={i.amount} date={i.dateTime} txid={i.txid} key={i.dateTime} />
              )}
            </div>
            <div>
              <div className={style.smheading}>{t('account.eve-wallet-output')}</div>
              {wallet && wallet.history.filter(i => i.type === 'WITHDRAW').map(i =>
                <Bill amount={i.amount} date={i.dateTime} txid={i.txid} key={i.dateTime} />
              )}
            </div>
          </div>
        </div>
        <Link to='/buy'><Button>{t('account.get-new-tokens')}</Button></Link>
      </div>
    </div>

    {(isTransferOpen && wallet) &&
      <Popup onClose={() => {
          setIsTransferOpen(false);
          getWallet();
        }
      }>
        <Withdrawal
          balance={wallet.balance}
        />
      </Popup>
    }
  </>
  );

  function changeName() {
    setIsChangeNameOpen('wait');
    axios.patch('/user', { firstName, lastName }, {
      headers: {
        'x-auth-token': localStorage.getItem('AuthToken'),
        'content-type': 'text/json'
      }
    })
      .then(() => {
        setIsChangeNameOpen(false);
        window.location.reload();
      })
      .catch(error => {
        console.warn(error.message);
        setIsChangeNameOpen(false);
        alert(t('error.something-went-wrong'));
      });
  }

  function getWallet() {
    axios.get('/wallet', {
      headers: {
        'x-auth-token': localStorage.getItem('AuthToken'),
        'content-type': 'text/json'
      }
    })
      .then((res) => {
        res.data.history = res.data.history.sort((a,b) => new Date(b.dateTime) - new Date(a.dateTime));
        setWallet(res.data);
      })
      .catch(error => {
        console.warn(error.message);
        alert(t('error.error-on-loading-wal'));
      });
  }
}

export default Account;
