import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from "i18next-browser-languagedetector";
import en from './en.yml';
import de from './de.yml';

i18n
	.use(initReactI18next)
	.use(detector)
	.init({
		fallbackLng: 'en',
		lng: 'en',
		resources: {
			en: { translations: en },
			de: { translations: de }
		},
		ns: ['translations'],
		defaultNS: 'translations'
		});

i18n.languages = ['en', 'de'];

export default i18n;
