import Button from "components/button";
import style from './styles.module.sass';
import { useTranslation } from 'react-i18next';

export default function Page404() {
const { t } = useTranslation();

return <div className={style.page}>
	<div>404</div>
	<div>{t('404.oh-no-page-not-found')}</div>
	<div>{t('404.it-looks-like-nothin')}</div>
	<Button onClick={()=>window.location.replace('/')}>{t('404.back-to-home')}</Button>
</div>
}