import axios from 'axios';
import Button from 'components/button';
import Icon from 'components/icon';
import Input from 'components/input';
import logoImg from 'img/logo-blackpink.png';
import { useState } from 'react';
import Web3 from 'web3';
import { v4 as uuidv4 } from 'uuid';
import style from './styles.module.sass';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const TX_URL = process.env.REACT_APP_BSCSCAN + '/tx/';
let id;

export default function Withdrawal({ balance }) {

	const { t } = useTranslation();
	const [withDrawStatus, setWithDrawStatus] = useState('inputData');
	const [address, setAddress] = useState(null);
	const [code, setCode] = useState(null);
	const [err, setErr] = useState(false);
	const [isRequest, setIsRequest] = useState(false);
	const [TXID, setTXID] = useState(null);

	return <div className={style.withdrawal}>
		<img src={logoImg} alt='' />
		<div>{t('withdrawal.transfer-tokens')}</div>
		{(withDrawStatus === 'inputData' || withDrawStatus === 'waitingCode') && <>
			<div className={style.form}>
				<div>{t('withdrawal.amount')}</div>
				<div className='grey'><b>{balance}</b></div>
			</div>
			<div className={style.form}>
				<div>{t('withdrawal.wallet-address')}</div>
				<Input
					onChange={v => setAddress(v)}
					disabled={withDrawStatus === 'waitingCode'}
				/>
			</div>
		</>}
		{withDrawStatus === 'inputData' && <>
			{err && <div className='red'>{err}</div>}
			<Button
				onClick={requestCode}
				disabled={isRequest}
			>
				{isRequest ? <Icon i='spin' /> : t('withdrawal.confirm')}
			</Button>
		</>}
		{withDrawStatus === 'waitingCode' && <>
			<p>{t('withdrawal.a-code-is-send-to-yo')}<br />{t('withdrawal.please-fill-in-the-c')}</p>
			<div className={style.form}>
				<div>{t('withdrawal.code')}</div>
				<Input onChange={v => setCode(v.toUpperCase())} />
			</div>
			{err && <div className='red'>{err}</div>}
			<Button
				onClick={withdraw}
				disabled={isRequest}
			>
				{isRequest ? <Icon i='spin' /> : t('withdrawal.confirm')}
			</Button></>
		}
		{withDrawStatus === 'success' && <div className={style.success}>
			<Icon i='ok-circled' />
			<div>{t('withdrawal.tokens-transferred')}</div>
			<div>{t('withdrawal.youre-tokens-are-now')}</div>
			<div>
				<Link
				to={TX_URL + TXID}
				target="_blank"
				rel="noopener noreferrer">
					txid: {TXID}
				</Link>
			</div>
			<Button onClick={()=>window.location.reload()}>{t('withdrawal.go-to-profile')}</Button>
		</div>
		}
		{withDrawStatus === 'error' &&
			<div className={style.error}>
				<div><Icon i='attention' /></div>
				<Button onClick={()=>window.location.reload()}>{t('error.something-went-wrong')}</Button>
			</div>
		}
	</div>

	function requestCode() {

		if (!Web3.utils.isAddress(address)) { setErr(t('withdrawal.wrong-wallet-address')); return; }
		setErr(false);
		setIsRequest(true)
		id = uuidv4();

		axios.post('/wallet/withdraw', { id, address }, {
			headers: {
				'x-auth-token': localStorage.getItem('AuthToken'),
				'content-type': 'text/json'
			}
		})
			.then(() => setWithDrawStatus('waitingCode'))
			.catch(error => {
				console.warn(error);
				setWithDrawStatus('error');
			})
			.finally(() => setIsRequest(false))
	}

	function withdraw() {

		setIsRequest(true)

		axios.post('/wallet/withdraw/confirm', { id, code }, {
			headers: {
				'x-auth-token': localStorage.getItem('AuthToken'),
				'content-type': 'text/json'
			}
		})
			.then((res) => {
				setTXID(res.data.txid);
				setWithDrawStatus('success');
			})
			.catch(error => {
				console.warn(error);
				setErr(t('error.wrong-code-try-again'));
			})
			.finally(() => setIsRequest(false))
	}
}