import style from './styles.module.sass';

function Popup(props) {
    return (<>
        <div className={style.background} onClick={()=>props?.onClose()}></div>
        <div className={style.popup}>
            <div className={style.closebtn} onClick={()=>props?.onClose()}>✕</div>
            {props.children}
        </div>
    </>);
}

export default Popup;