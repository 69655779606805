import { Component } from 'react';
import styles from './styles.module.sass';

export default class Input extends Component {
	#onChange = ({target}) => {
		this.props.onChange?.(target.value);
	};

	render() {
		const { children, class: className } = this.props;
		return <input
			{...this.props}
			className={`${styles.input} ${className || ''}`}
			onChange={this.#onChange}
		>
			{children}
		</input>;
	}
}