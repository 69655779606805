import { Component } from 'react';
import styles from './styles.module.sass';

export default class Button extends Component {
	#onClick = () => {
		this.props.onClick?.();
	};

	render() {
		const { disabled, type, children, class: className, unstyled } = this.props;
		return <button
			type={type || 'submit'}
			className={`${unstyled ? styles.unstyled : styles.button} ${className}`}
			disabled={disabled}
			onClick={disabled ? null : this.#onClick}
		>
			{children}
		</button>;
	}
}