import { useEffect, useState } from 'react';
import Home from 'pages/home';
import Popup from 'components/popup';
import Login from 'pages/login';
import Signup from 'pages/signup';

function LoginPage() {

	const [signup, setSignup] = useState(false);
  const [login, setLogin] = useState(false);
	useEffect(()=>{
		setTimeout(()=> setLogin(true), 500)}, []
	);

	return <>
		<Home />
		{signup &&
      <Popup onClose={() => setSignup(false)}>
          <Signup
            onLogin={() => { setLogin(true); setSignup(false) }}
          />
      </Popup>
    }
    {login &&
      <Popup onClose={() => setLogin(false)}>
        <Login
          onSignup={() => { setLogin(false); setSignup(true) }}
        />
      </Popup>
    }
	</>
}
export default LoginPage;