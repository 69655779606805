import logoImg from 'img/logo-blackpink.png';
import style from './styles.module.sass';
import Button from 'components/button';
import { Link, useParams } from 'react-router-dom';
import Home from 'pages/home';
import Popup from 'components/popup';
import Icon from 'components/icon';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function Activate() {

  const { t } = useTranslation();
  let { id } = useParams();
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    axios.post('/user/activate', { id })
      .then((res) => {
        setPopup('success');
        localStorage.removeItem('AuthToken');
        localStorage.setItem('AuthToken', res.data.token);
      })
      .catch(error => { console.warn(error.message); setPopup('error') });
  }, [id])

  return (<div>
    <Home />
    {popup && <Popup onClose={() => { setTimeout(() => { document.location.href="/"; }, 500) }}>
      <div className={style.ok}>
        {popup === 'success' ? <>
          <img src={logoImg} alt='' />
          <Icon i='ok-circled' />
          <div><b>{t('activate.email-verified')}</b></div>
          <div>{t('activate.welcome-to-your-eve-')}</div>
          <br />
          <Link to='/buy'><Button>{t('home.get-your-token')}</Button></Link>
          <Link reloadDocument to='/account'><Button>{t('activate.get-to-profile')}</Button></Link></>
          : <>
            <Icon i='attention' />
            {id}
            <div><b>{t('activate.code-is-expired-or-w')}</b></div></>
        }
      </div>
    </Popup>}
  </div>);
}

export default Activate;
