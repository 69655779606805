import axios from "axios";
import Button from "components/button";
import Icon from "components/icon";
import Popup from 'components/popup';
import logo2Img from 'img/logo-blackpink.png';
import logoImg from 'img/logo-whitepink.png';
import Login from 'pages/login';
import Signup from 'pages/signup';
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import style from './styles.module.sass';
import { useTranslation } from 'react-i18next';

function Header({user}) {
  const { t, i18n } = useTranslation();
  const [signup, setSignup] = useState(false);
  const [login, setLogin] = useState(false);
  const [cookies, setCookies] = useState(localStorage.getItem('cookies') || false);
  const [userInfo, setUserInfo] = useState(user);
  const [lang, setLang] = useState(user.language || 'en');
  const [langs, setLangs] = useState();

  const headerRef = useRef(null);
  const logoRef = useRef(null);

  window.onscroll = function () {
    if (document.documentElement.scrollTop > 80) {
      headerRef.current.classList.add(style.header2);
      logoRef.current.src = logo2Img;
    } else {
      headerRef.current.classList.remove(style.header2);
      logoRef.current.src = logoImg;
    }
  };

  useEffect(() => {
    setUserInfo(user);
    setLangs(Object.keys(i18n.services.resourceStore.data));
    if (i18n.language!==user.language) {
      setLang(user.language);
      i18n.changeLanguage(user.language);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    let l = localStorage.getItem('language');
    if (l) {
      setLang(l);
      i18n.changeLanguage(l);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (<>
    <header className={style.header} ref={headerRef}>
      <Link to='/'><img src={logoImg} alt='' ref={logoRef} /></Link>
      <div>
        <div><Link to='/'>{t('header.home')}</Link></div>
        <div>
          {userInfo ?
            <><Link to='/account'><Icon i='user' /> {userInfo.firstName}</Link>
              <Button unstyled={true} onClick={logout}><Icon i='exit'/></Button></>
            : <><Button unstyled={true} onClick={() => setLogin(true)}>{t('header.login')}</Button>
              <span>•</span>
              <Button unstyled={true} onClick={() => setSignup(true)}>{t('header.sign-up')}</Button></>
          }
          { langs &&
            <select
              onChange={e=>changeLang(e.target.value)}
              value={lang}>
              { langs.map(l => <option value={l} key={l}>{l}</option>) }
          </select>
          }
        </div>
      </div>
    </header>
    {!cookies &&
      <div className={style.cookies}>
        <div><img src={logo2Img} alt='' /></div>
        <div>
          <div><b>{t('cookies.cookies')}</b></div>
          <div>{t('cookies.we-use-cookies-to-st')}
          </div>
        </div>
        <div>
          <div><Button onClick={() => acceptCookie()}>{t('cookies.accept')}</Button></div>
          <div><a href='https://www.evehr.io/terms-privacy/' target='_blank' rel="noreferrer">{t('cookies.privacy-policy')}</a></div>
        </div>
        <div onClick={() => { localStorage.setItem('cookies', 1); setCookies(true) }}>✕</div>
      </div>
    }
    {signup &&
      <Popup onClose={() => setSignup(false)}>
          <Signup
            onLogin={() => { setLogin(true); setSignup(false) }}
          />
      </Popup>
    }
    {login &&
      <Popup onClose={() => setLogin(false)}>
        <Login
          onSignup={() => { setLogin(false); setSignup(true) }}
        />
      </Popup>
    }
  </>
  );

  function logout() {
    localStorage.removeItem('AuthToken');
    setTimeout(() => { window.location.reload() }, 500);
  }

  function acceptCookie() {
    localStorage.setItem('cookies', 1);
    setCookies(true);
    if (!userInfo) return;
    axios.patch('/user', { cookieAccepted: true }, {
      headers: {
        'x-auth-token': localStorage.getItem('AuthToken'),
        'content-type': 'text/json'
      }
    })
      .catch(error => {
        console.warn(error.message);
      });
  }

  function changeLang(lang) {
    i18n.changeLanguage(lang);
    setLang(lang);
    localStorage.setItem('language', lang);
    document.title = t('title.eve-hr-platform-toke');
    if (!userInfo) return;
    setUserInfo(Object.assign(user, {language: lang}));
    axios.patch('/user', { language: lang }, {
      headers: {
        'x-auth-token': localStorage.getItem('AuthToken'),
        'content-type': 'text/json'
      }
    })
      .catch(error => {
        console.warn(error.message);
      });
  }

}

export default Header;
