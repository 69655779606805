import axios from "axios";

const Auth = async () => {

	if (!localStorage.getItem('AuthToken')) return false;
	try {
		const auth = await axios.get('/user', {
			headers: {
				'x-auth-token': localStorage.getItem('AuthToken'),
				'content-type': 'text/json'
			}});
		if (auth.data?.cookieAccepted) localStorage.setItem('cookies', 1);
		return auth.data;
	} catch (e) {
		console.warn(e.message);
		return false;
	}
}

export default Auth;