import { useState } from 'react';
import logoImg from 'img/logo-blackpink.png';
import style from './styles.module.sass';
import Button from 'components/button';
import Input from 'components/input';
import axios from 'axios';
import Icon from 'components/icon';
import { useTranslation } from 'react-i18next';

function Signup(props) {

  const { t, i18n } = useTranslation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [err, setErr] = useState(null);
  const [wait, setWait] = useState(false);
  const [verify, setVerify] = useState(false);

  return (<div>
    <div className={style.signup}>
      <img src={logoImg} alt=''></img>
      <div>
        <div>
          <span onClick={() => props.onLogin()}>{t('header.login')}</span><span></span><span>{t('login.sign-up')}</span>
        </div>
        <div>{t('signup.create-an-account-to')}</div>
        <Input type='text' placeholder={t('account.first-name')} onChange={v => setFirstName(v)} />
        <Input type='text' placeholder={t('account.last-name')} onChange={v => setLastName(v)} />
        <Input type='email' placeholder={t('login.email')} onChange={v => setEmail(v)} />
        <Input type='password' placeholder={t('login.password')} onChange={v => setPassword(v)} />
        <Input type='password' placeholder={t('signup.confirm-password')} onChange={v => setPassword2(v)} />
        {err && <div className={style.err}><Icon i='attention' /> {err}</div>}
        {!verify
          ? <Button type='submit' onClick={() => register()}>
            {wait ? <Icon i='spin' /> : t('login.sign-up')}
          </Button>
          : <div className={style.wait}>
            <Icon i='spin' />
            <div>{t('signup.an-email-has-been-se')} <b>{email}</b></div>
            <div>{t('signup.please-verify-your-e')}</div>
          </div>
        }
      </div>
    </div>
  </div >);

  function register() {

    if (firstName.length < 2) { setErr(t('error.first-name-not-valid')); return; }
    if (lastName.length < 2) { setErr(t('error.last-name-not-valid')); return; }
    if (!/\S+@\S+\.\S+/.test(email)) { setErr(t('error.email-not-valid')); return; }
    if (!(password === password2) || password.length < 3) { setErr(t('error.passwords-do-not-mat')); return; }
    setErr(null);

    setWait(true);
    localStorage.removeItem('AuthToken');
    axios.post('/user/register', { firstName, lastName, email, password, language: i18n.language })
      .then(() => {
        setVerify(true); setWait(false)
        waitActivate();
      })
      .catch(error => {
        console.warn(error.message); setWait(false)
      });
  }
}

function waitActivate() {
  setInterval(()=> {
    if (localStorage.getItem('AuthToken')) window.location.replace('/');
  } ,1000);
}

export default Signup;
